@import "../../../../utilities/variables";

.TopAlignLabelField {
  display: flex;
  flex-direction: column;
  //flex: 7 0 0;
  //padding: 12px 24px;
}

//.TopAlignLabelField--medium {
//  flex: 7 0 0;
//  //padding: 12px 24px;
//}
//
//.TopAlignLabelField--small {
//  flex: 3 0 0;
//  //padding: 12px 24px;
//}
//
//.TopAlignLabelField--large {
//  flex: 1 0 0;
//  //padding: 12px 24px;
//}

.TopAlignLabelField__label {
  color: rgb(33, 33, 33);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 0 0 8px 0;
  min-width: 120px;
}