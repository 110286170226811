@import "../../../utilities/variables";

.Notice {
  margin: 16px 0 16px 0;
  background: $notice_background;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  max-width: 75%;
}

.Notice-full-width {
  width: 100%;
}

.Notice-success {
  background: $success_notice_background;
}

.Notice-error {
  background: $error_notice_background;
}

.Notice__wrapper {
  display: flex;
  justify-content: center;
}

.Notice__text {
  color: $secondary_text_color;
  font-size: 14px;
  line-height: 20px;
}

.Notice__text-success {
  color: $success_notice_text_color;
}

.Notice__text-error {
  color: $error_notice_text_color;
}

.Notice__icon {
  color: $primary_color;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px 0 0;
}

.Notice__icon-success {
  color: $success_notice_text_color;
}

.Notice__icon-error {
  color: $error_notice_text_color;
}