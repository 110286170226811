@import "../../../../utilities/variables";

.TextArea {
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  //min-width: 200px;
  padding: 6px 8px;
  -webkit-appearance: none;
  appearance: none;
  background: white;
  border: 0;
  color: $primary_text_color;
  cursor: text;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  min-height: 32px;
  display: inline-flex;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  outline: 0;
  text-align: left;
  text-decoration: none;
  transition: color .24s;
  -ms-user-select: auto;
  -webkit-user-select: auto;
  user-select: auto;
  vertical-align: middle;
  white-space: normal;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  word-break: normal;
  z-index: 1;
  overflow-x: hidden;
}