@import "../../../utilities/variables";

.FormErrorFooter {
  background-color: $form_error_background_color;
  padding: 12px;
  border-radius: 4px;
}

.FormErrorFooter__text {
  line-height: 20px;
  font-size: 14px;
  color: $form_error_text_color;
}