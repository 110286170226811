@import "../../../utilities/variables";

$items_height: calc($top-bar-height - 24px);

.business-header {
  flex: 0 0 $top-bar-height;
  height: $top-bar-height;
  display: inline-flex;
  padding: 12px 0;
  align-items: center;
}

.business-header__logo-container {
  flex: 0 0 $items_height;
  height: $items_height;
}

.business-header__logo {
  height: 100%;
  width: $items_height;
  border-radius: 8px;
  object-fit: scale-down;
}

.business-header__business-name {
  flex: 1 1 auto;
  margin: 0 10px;
  font-weight: 600;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: $items_height;
  color: $primary_text_color;
}

.business-header__chevron-icon {
  font-size: 24px;
  flex: 0 0 20px;
  cursor: pointer;
}