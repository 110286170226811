@import "../../../../utilities/variables";

.Field__input-container {
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  //flex: 70% 0 0;
  min-width: 200px;
}

.Field__text-container {
  padding: 5px 0 0 0;
}

.Field__input {
  -webkit-appearance: none;
  appearance: none;
  background: white;
  border: 0;
  border-radius: 4px;
  color: $primary_text_color;
  cursor: text;
  display: inline-flex;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  min-height: 32px;
  outline: 0;
  padding: 4px 8px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: color .24s;
  -ms-user-select: auto;
  -webkit-user-select: auto;
  user-select: auto;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  word-break: normal;
  z-index: 1;
}