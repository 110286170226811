.Form {
  display: flex;
  flex-direction: column;
  padding: 0 0 24px 0;
}

.Form__section-fields {
  margin: 24px 0 16px 0;
}

.Form__row {
  display: flex;
  padding: 12px 0;
  //flex-wrap: wrap;
}

.Form__full-screen-container {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.Form__inside-content-container {
  background: #F7FAFC;
  margin: 0 0 -16px 0;
}

.Form__button_row {
  display: flex;
  height: 44px;
}

.Form__inside-content-padding-container {
  padding: 0 16px;
}
