@import "../../../utilities/variables";

.Button {
  flex-shrink: 0;
  background-color: $button_background_color;
  border-radius: 4px;
  min-height: 28px;
  padding: 0;
  border: 1px solid #BDBDBD;
  //margin: 0 0 0 12px;
  cursor: pointer;
  //box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
}

.Button:hover {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
}

.Button:active {
  background-color: #eee;
}

.Button__content {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  padding: 4px 8px;
}

.Button__label {
  font-weight: 500;
  font-size: 14px;
  margin: 0 0 0 4px;
  line-height: 20px;
  color: #3c4257;
}

.Button__icon {
  color: #3c4257;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Button__fill {
  flex-grow: 1;
}

.WhatsAppButton {
  background-color: #23C004;
  border: 1px solid #23C004;
}

.PrimaryButton {
  background-color: $primary_button_background_color;
  border: 1px solid $primary_button_background_color;
  //box-shadow: 0 1px 2px #0036cb;
}

.RedButton {
  background-color: $red_button_background_color;
  border: 1px solid $red_button_background_color;
  //box-shadow: 0 1px 2px #0036cb;
}

.RedButton:hover {
  box-shadow: 0 1px 2px $red_button_background_color;
}

.RedButton:active {
  background-color: $red_button_active_background_color;
  border: 1px solid #aaa;
}

.WhatsAppButton__label,
.RedButton__label,
.PrimaryButton__label {
  color: $primary_button_title_color;
}

.WhatsAppButton__icon,
.RedButton__icon,
.PrimaryButton__icon {
  color: $primary_button_title_color;
}

.WhatsAppButton:hover {
  box-shadow: 0 1px 2px #23C004;
}

.PrimaryButton:hover {
  box-shadow: 0 1px 2px $primary_button_background_color;
}

.WhatsAppButton:active,
.PrimaryButton:active {
  box-shadow: 0 1px 2px #888;
  border: 1px solid #aaa;
  background-color: #bbb;
}

.Button[disabled], html input[disabled] {
  cursor: default;
  border: 1px solid #aaa;
  background-color: #ccc;
}

.WhatsAppButton[disabled],
.PrimaryButton[disabled] {
  border: 1px solid #666;
  background-color: #777;
}

.Button[disabled] > div {
  cursor: default;
  opacity: 0.75;
}

.Button[disabled]:hover {
  box-shadow: none;
}

.TextButton {
  background: none;
  border: none;
  outline: inherit;
  border-radius: 4px;
  min-height: 28px;
}

.TextButton__text {
  color: $primary_color;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  cursor: pointer;
}

.TextButton__text:hover {
  color: #777;
  cursor: pointer;
}

.TextButton__text:active {
  color: #ddd;
}

.DestructiveTextButton__text {
  color: $red_button_background_color;
}

.DestructiveTextButton__text:hover {
  color: #777;
  cursor: pointer;
}

.DestructiveTextButton__text:active {
  color: $red_button_active_background_color;
}