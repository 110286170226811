@import "../../../../utilities/variables";

.LeftAlignLabelField {
  display: flex;
  padding: 10px 10px;
}

.LeftAlignLabelField__label {
  color: $secondary_text_color;
  flex: 25% 0 0;
  font-size: 14px;
  line-height: 22px;
  margin: 0 10px 0 0;
  text-align: right;
  min-width: 120px;
  padding: 5px 0 0 0;
}

.LeftAlignLabelField__fields {
  align-items: flex-start;
  flex: 0 0 55%;
  max-width: $left_align_label_field__fields_max_width;
  display: flex;
  flex-direction: column;
}

.LeftAlignLabelField__input-field {
  width: $left_align_label_field__input_width;
  max-width: $left_align_label_field__input_max_width;
}

.LeftAlignLabelField__input-brief {
  margin: 8px 0 0 0;
  color: $secondary_text_color;
  font-size: 13px;
  line-height: 20px;
}

.LeftAlignLabelField__input-button {
  margin: 8px 0 0 0;
}

.LeftAlignLabelField__input {
  margin: 8px 0 0 0;
  color: $secondary_text_color;
  font-size: 13px;
  line-height: 20px;
}