@import "../../../../utilities/variables";

.FileInput {
  width: 100%;
  aspect-ratio: 1;
  border-style: dashed;
  border-width: 1px;
  border-color: $file_input_border_color;
  border-radius: 6px;
  display: grid;
  align-items: center;
  justify-items: center;
  overflow: hidden;
}

.FileInput:hover {
  background-color: $file_input_background_color;
}

.FileInput-preview-border {
  border-style: solid;
}

.FileInput > * {
  grid-area: 1/1/1/1;
}

.FileInput__input {
  cursor: pointer;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
}

.FileInput__preview-image {
  width: 100%;
  height: 100%;
  max-height: 100%;
  z-index: 9;
  object-fit: cover;
  image-rendering: smooth;
}

.FileInput__button-span {
  font-size: 14px;
  font-weight: 500;
  color: $primary_button_background_color;
  z-index: 1;
}

.FileInput__button-span:hover {
  color: lightgray;
}

.FileInput__button-span:active {
  color: darkgray;
}

.FileInput__delete_button-span {
  font-size: 24px;
  height: 24px;
  color: gray;
  z-index: 11;
  align-self: flex-start;
  justify-self: flex-end;
  margin: 4px 4px;
  cursor: pointer;
}

.FileInput__delete_button-span:hover {
  color: lightgray;
}

.FileInput__delete_button-span:active {
  color: darkgray;
}

// Shop Category Cover

.FileInput-category-cover {
  aspect-ratio: $category_cover_aspect_ratio;
}

.FileInput__preview-image-category-cover {
  aspect-ratio: $category_cover_aspect_ratio;
}

// Sale Event Cover

.FileInput-sale-event-cover {
  aspect-ratio: $sale_event_cover_aspect_ratio;
}

.FileInput__preview-image-sale-event-cover {
  aspect-ratio: $sale_event_cover_aspect_ratio;
}

// Product Image

.FileInput-product-image-cover {
  aspect-ratio: $product_cover_aspect_ratio;
}

.FileInput__preview-image-product-image-cover {
  aspect-ratio: $product_cover_aspect_ratio;
}

// Product Image Cover

.FileInput-documentaries-category-cover {
  aspect-ratio: $documentaries_category_cover_aspect_ratio;
}

.FileInput__preview-image-documentaries-category-cover {
  aspect-ratio: $documentaries_category_cover_aspect_ratio;
}

// Documentary Cover

.FileInput-documentary-cover {
  aspect-ratio: $documentary_cover_aspect_ratio;
}

.FileInput__preview-image-documentary-cover {
  aspect-ratio: $documentary_cover_aspect_ratio;
}

// Videos Cover

.FileInput-video-cover {
  aspect-ratio: $video_cover_aspect_ratio;
}

.FileInput__preview-image-video-cover {
  aspect-ratio: $video_cover_aspect_ratio;
}

// Podcast Cover

.FileInput-podcast-cover {
  aspect-ratio: $podcast_cover_aspect_ratio;
}

.FileInput__preview-image-podcast-cover {
  aspect-ratio: $podcast_cover_aspect_ratio;
}

// Post Cover

.FileInput-post-cover {
  aspect-ratio: $post_cover_aspect_ratio;
}

.FileInput__preview-image-post-cover {
  aspect-ratio: $post_cover_aspect_ratio;
}

// Post Section Cover

.FileInput-post-section-cover {
  aspect-ratio: $post_section_cover_aspect_ratio;
}

.FileInput__preview-image-post-section-cover {
  aspect-ratio: $post_section_cover_aspect_ratio;
}

.FileInput-empty-post-section-cover {
  aspect-ratio: $empty_post_section_cover_aspect_ratio;
}

.FileInput__preview-image-empty-post-section-cover {
  aspect-ratio: $empty_post_section_cover_aspect_ratio;
}