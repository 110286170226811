@import "../../utilities/variables";

.TopBar {
  height: $top-bar-height;
  display: flex;
  align-items: center;
}

.TopBar__wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  padding: 0 20px;
}

.TopBar__buttons-container {
  display: flex;
  align-items: center;
}