$top-bar-height: 60px;
$side-var-width: 250px;

// Colors
$primary_color: #3c4421;
$neutral_10_color: #e6e6e6;
$neutral_40_color: #989898;
$neutral_60_color: #686868;
$neutral_70_color: #484848;
$neutral_80_color: #282828;

// Theme
$separator_color: $neutral_10_color;

// Side Bar
$side_bar_background_color: #f7fafc;
$side_bar_section_title_color: $neutral_60_color;
$side_bar_section_item_color: #6D725B;
$side_bar_item_title_color: $neutral_80_color;

// Icon
$success_icon_color: $primary_color;

// Form Error
$form_error_background_color: #fde2dd;
$form_error_text_color: #a41c4e;

// Button
$button_background_color: #ffffff;
$button_title_color: $neutral_80_color;

$primary_button_background_color: $primary_color;
$primary_button_title_color: #fff;

$red_button_background_color: #a41c4e;
$red_button_title_color: #fff;
$red_button_active_background_color: #fde2dd;

// Segmented Control
$segment_border_color: $primary_color;
$segment_title_color: #2f53fc;

// Select
$select_icon_color: $neutral_80_color;

// Radio
$radio_background_color: $primary_color;

// Text
$primary_text_color: $neutral_80_color;
$secondary_text_color: $neutral_60_color;

// Badge
$success_badge_background: #cbf4c9;
$success_badge_text_color: #1c6144;

$warning_badge_background: #f6e6b9;
$warning_badge_text_color: #983806;

$information_badge_background: #d4e9fd;
$information_badge_text_color: #3d4dac;

$error_badge_background: #fde2dd;
$error_badge_text_color: #a41c4e;

// Notice
$notice_background: $neutral_10_color;
$success_notice_background: #cbf4c9;
$success_notice_text_color: #1c6144;
$error_notice_background: #fde2dd;
$error_notice_text_color: #a41c4e;

// Table
$table_row_hover_background_color: $neutral_10_color;

// File Input
$file_input_border_color: $neutral_40_color;
$file_input_background_color: #ffffff;

// Input Width
$left_align_label_field__fields_max_width: 380px;
$left_align_label_field__input_width: 360px;
$left_align_label_field__input_max_width: 360px;

// Ratios

$post_cover_aspect_ratio: 1/1;

$post_section_cover_aspect_ratio: 0;

$empty_post_section_cover_aspect_ratio: 4;

$category_cover_aspect_ratio: 1/1.136;

$sale_event_cover_aspect_ratio: 1/0.4830;

$product_cover_aspect_ratio: 1/1;

$documentaries_category_cover_aspect_ratio: 191/100;
$books_category_cover_aspect_ratio: 191/100;

$documentary_cover_aspect_ratio: 1/1.4;
$book_cover_aspect_ratio: 1/1.4;

$podcast_cover_aspect_ratio: 1/1.136;

$video_cover_aspect_ratio: 16/9;