.Card {
  border-radius: 6px;
  box-shadow: rgba(60,55,87,0.08) 0 15px 35px 0, rgba(0, 0, 0, 0.12) 0 5px 15px 0;
  background-color: white;
  padding: 20px 32px;
}

.Card__flex-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}