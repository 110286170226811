@import "../../../utilities/variables";

.PageTitle {
  padding: 24px 0 16px 0;
}

.PageTitle__top-bar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PageTitle__buttons_bar {
  display: flex;
  align-items: center;
}

.PageTitle__title {
  font-weight: 700;
  font-size: 28px;
}

.PageTitle__brief-container {
  margin: 16px 0 0 0;
}

.PageTitle__brief {
  color: $secondary_text_color;
  font-size: 15px;
  line-height: 20px;
}

.PageTitle__subtitle-container {
  margin: 16px 0 0 0;
  display: flex;
  align-items: center;
}

.PageTitle__subtitle {
  color: $secondary_text_color;
  font-size: 20px;
  line-height: 24px;
  margin: 0 16px 0 0;
}