@import "./utilities/variables";

* {
  box-sizing: border-box;
  word-wrap: break-word;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.Root {
  display: flex;
  align-items: stretch;
  //font-size: 14px;
}

.content {
  flex: 1
}

.page-container {
  padding: 0 20px 0 40px;
}

.divider-bottom-1 {
  box-shadow: inset 0 -1px $separator_color;
}

.Content-bottom-margin {
  margin: 0 0 180px 0;
}

blockquote,
body,
button,
dd,
div,
dl,
dt,
fieldset,
form,
input,
legend,
li,
ol,
p,
td,
textarea,
th,
ul {
  margin: 0;
  outline: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

body,
button,
html,
input {
  -webkit-font-smoothing: antialiased;
}

td, th {
  padding: 0;
}

.fadeIn {
  animation: fadeInAnimation 1s;
}

@keyframes fadeInAnimation {
  from { opacity: 0; }
  to   { opacity: 1; }
}