@import "../../../utilities/variables";

.FieldsTitle {
  padding: 12px 16px;
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.FieldsTitle__span {
  font-weight: 700;
  font-size: 16px;
}