@import "../../../utilities/variables";

.TableFooter {
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.TableFooter__text {
  color: $secondary_text_color;
  font-size: 14px;
  line-height: 22px;
}