@import "../../../utilities/variables";

.SectionTitle {
  padding: 32px 0 16px 0;
}

.SectionTitle__title {
  font-weight: 700;
  font-size: 20px;
}

.SectionTitle__brief-container {
  margin: 16px 0 0 0;
}

.SectionTitle__brief {
  color: $secondary_text_color;
  font-size: 15px;
  line-height: 20px;
}