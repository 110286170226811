@import "../../utilities/variables";

.LogIn {
  display: flex;
  justify-content: center;
}

.LogIn__container {
  display: flex;
  flex-direction: column;
  width: 540px;
}

.LogIn__logo-container {
  padding: 44px 20px;
  height: 200px;
  margin: 0 auto;
}

.LogIn__logo-container:first-child {
  width: 40%;
  display:block;
}

.LogIn__footer {
  margin: 32px 0;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  color: $secondary_text_color;
}