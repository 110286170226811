@import "../../utilities/variables";

.SideBar {
  background-color: $side_bar_background_color;
  display: inline-flex;
  flex: 0 0 $side-var-width;
  flex-direction: column;
  padding: 0 24px;
  -webkit-user-select: none;
  user-select: none;
  width: $side-var-width;
  overflow: auto;
}

.SideBar__wrapper {
  margin: 0 0 120px 0;
}

.SideBar__section {
  margin: 24px 0 0 0;
}

.SideBar__section-title {
  color: $side_bar_section_title_color;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.SideBar__section-items-list {
  list-style: none;
  margin: 12px 0 0 0;
  padding-inline-start: 0;
}

.SideBar__section-items-list > a > li {
  margin: 0 0 8px 0;
}

.SideBar__section-item {
  align-items: center;
  border-radius: 4px;
  color: $side_bar_item_title_color;
  cursor: pointer;
  display: flex;
  padding: 8px;
}

.active > .SideBar__section-item,
.SideBar__section-item:hover {
  background-color: $side_bar_section_item_color;
  color: #fff;
}

.SideBar__section-item-title {
  font-size: 14px;
  margin: 0 0 0 8px;
}