@import "../../../../utilities/variables";

.AudioFileInput {
  width: 100%;
  border-style: dashed;
  border-width: 1px;
  border-color: $file_input_border_color;
  border-radius: 6px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  overflow: hidden;
  padding: 20px 0;
}

.AudioFileInput__input {
  cursor: pointer;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
}

.AudioFileInput__filename_span {
  font-size: 14px;
  font-weight: 400;
  color: $primary_text_color;
  z-index: 1;
  text-align: center;
}

.AudioFileInput__upload_container {
  display: grid;
  align-items: center;
  justify-items: center;
}

.AudioFileInput__upload_container > * {
  grid-area: 1/1/1/1;
}