@import "../../../../utilities/variables";

.Date__input-container {
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
  min-width: 200px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.Date__input {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 4px;
  color: $primary_text_color;
  cursor: pointer;
  display: inline-flex;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  min-height: 32px;
  outline: 0;
  padding: 4px 24px 4px 8px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: color .24s;
  -ms-user-select: auto;
  -webkit-user-select: auto;
  user-select: auto;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  word-break: normal;
  z-index: 1;
}

.Date__icon {
  //position: absolute;
  //right: 16px;
  //top: 50%;
  //margin-top: 5px;
  color: $select_icon_color;
  margin: 0 6px 0 4px;
}

.Date__popper  {
  z-index: 1000;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  //display: none;
  //-webkit-appearance: none;
}