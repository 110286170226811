@import "../../utilities/variables";

$post_cover_width: 100px;
$post_cover_table_width: $post_cover_width + 40px;

$category_cover_width: 100px;
$category_cover_table_width: $category_cover_width + 40px;

$sale_event_cover_width: 200px;
$sale_event_cover_table_width: $sale_event_cover_width + 40px;

$product_cover_width: 100px;
$product_cover_table_width: $product_cover_width + 40px;

$documentaries_category_cover_width: 200px;
$documentaries_category_cover_table_width: $documentaries_category_cover_width + 40px;

$documentary_cover_width: 100px;
$documentary_cover_table_width: $documentary_cover_width + 40px;

$video_cover_width: 200px;
$video_cover_table_width: $video_cover_width + 40px;

$podcast_cover_width: 100px;
$podcast_cover_table_width: $podcast_cover_width + 40px;

.Table {
  border-collapse: collapse;
  margin: 20px 0 16px 0;
  max-width: 100%;
  width: 100%;
}

.Table__header-data {
  box-shadow: $separator_color 0px -1px 0px 0px inset;
  color: $secondary_text_color;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  white-space: nowrap;
}

.Table__body-data {
  box-shadow: $separator_color 0px -1px 0px 0px inset;
  color: $primary_text_color;
  font-size: 14px;
  line-height: 20px;
  //white-space: nowrap;
  word-wrap: break-word;
}

.Table__data-container {
  padding: 10px 8px;
}

.Table__primary-text-span {
  color: $primary_text_color;
  font-weight: 500;
}

.Table__secondary-text-span {
  color: $secondary_text_color;
}

.Table__two-lines-span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.Table__button-span {
  display: flex;
  max-width: 120px;
}

.Table__buttons-span {
  display: flex;
  max-width: 250px;
}

.Table__multiple-items-span {
  display: flex;
  gap: 8px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.Table__row:hover {
  background: #f7fafc;
  cursor: pointer;
}

// Image

.Table__image-container {
  display: grid;
  align-items: center;
  justify-items: center;
  border-radius: 8px;
  overflow: hidden;
}

.Table__image-container > * {
  grid-area: 1/1/1/1;
}

.Table__image {
  object-fit: cover;
  image-rendering: smooth;
  width: 100%;
  z-index:1;
}

.Table_image-overlay {
  width:100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index:10;
}

// Shop category

.Table__category-image-container {
  width: $category_cover_width;
}

.Table__category-cover-image {
  aspect-ratio: $category_cover_aspect_ratio;
}

.Table__category-body-data__image {
  width: $category_cover_table_width;
}

// Sale event

.Table__sale-event-image-container {
  width: $sale_event_cover_width;
}

.Table__sale-event-cover-image {
  aspect-ratio: $sale_event_cover_aspect_ratio;
}

.Table__sale-event-body-data__image {
  width: $sale_event_cover_table_width;
}

// Product

.Table__product-image-container {
  width: $product_cover_width;
}

.Table__product-cover-image {
  aspect-ratio: $product_cover_aspect_ratio;
}

.Table__product-body-data__image {
  width: $product_cover_table_width;
}

// Post

.Table__post-image-container {
  width: $post_cover_width;
}

.Table__post-cover-image {
  aspect-ratio: $post_cover_aspect_ratio;
}

.Table__post-body-data__image {
  width: $post_cover_table_width;
}

// Documentaries Category

.Table__documentaries-category-cover-photo-container {
  width: $documentaries_category_cover_width;
}

.Table__documentaries-category-cover-photo {
  aspect-ratio: $documentaries_category_cover_aspect_ratio;
}

.Table__documentaries-category-body-data__image {
  width: $documentaries_category_cover_table_width;
}

// Documentary

.Table__documentary-cover-photo-container {
  width: $documentary_cover_width;
}

.Table__documentary-cover-photo {
  aspect-ratio: $documentary_cover_aspect_ratio;
}

.Table__documentary-body-data__image {
  width: $documentary_cover_table_width;
}

// Video

.Table__video-cover-photo-container {
  width: $video_cover_width;
}

.Table__video-cover-photo {
  aspect-ratio: $video_cover_aspect_ratio;
}

.Table__video-body-data__image {
  width: $video_cover_table_width;
}

// Podcast

.Table__podcast-cover-photo-container {
  width: $podcast_cover_width;
}

.Table__podcast-cover-photo {
  aspect-ratio: $podcast_cover_aspect_ratio;
}

.Table__podcast-body-data__image {
  width: $podcast_cover_table_width;
}